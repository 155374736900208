import React,{useState} from "react";
import "./style.css";
import "./bulma.min.css";
import "./animate.min.css";
import DatePicker from "react-datepicker";
import FetchInfo from "./FetchInfo";
import "react-datepicker/dist/react-datepicker.css";

export default function App() {
const [startDate, setStartDate] = useState(new Date());
const [endDate, setendDate] = useState(new Date());

const StartDate = () => {
  return (
    <DatePicker className="button" selected={startDate} onChange={(date) => {setStartDate(date);}} />
  );
};
const EndDate = () => {
  return (
    <DatePicker className="button" selected={endDate} onChange={(date) => setendDate(date)} />
  );
};

const checkDate = () => {
  window.alert("StartDate: "+startDate+"\nEndDate: "+endDate);
}
  return (
    <div className="mainTable">
      <h1>{process.env.REACT_APP_TITLE}</h1>
      <table className="dt_table">
        <tbody>
        <tr className="dpicker tr">
            <td className="td">
            <h2>StartDate</h2>
            <StartDate className="startd" />
            </td>
            <td className="td">
            <h2>EndDate</h2>
            <EndDate/>
            </td>
          </tr>
        </tbody>
      </table>
      <FetchInfo startDate={startDate} endDate={endDate} />
    </div>
  );
}
