import React,{useState,useEffect} from "react";
import "./style.css";
import "./bulma.min.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'bulma-toast';

export default function FetchInfo(props) {
const [data, setData] = useState("");
const [jsonD, setJsonD] = useState([]);
const [loading, setLoading] = useState(false);
var jsonData = [];

/*
useEffect(() => {
    console.log(jsonData);
}, [data, jsonData]);
*/

const limit = 60;
const authToke = btoa(process.env.REACT_APP_APIKEY+":");
const startDate = ""+
(props.startDate.getFullYear())+"-"+
(("0"+(props.startDate.getMonth()+1).toString()).slice(-2))+"-"+
(("0"+props.startDate.getDate()).toString().slice(-2));

const endDate = ""+
((props.endDate.getFullYear()))+"-"+
(("0"+(props.endDate.getMonth()+1)).toString().slice(-2))+"-"+
(("0"+props.endDate.getDate()).toString().slice(-2));

const htRequestBuild = "https://api.moverbase.com/v1/jobs/?limit="+limit+"&offset=0&order=-date&start="+startDate+"&end="+endDate;
const jobInfoRequest = "https://api.moverbase.com/v1/jobs/";
const reqOpts = {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: {
        "content-type": "application/json",
        "Authorization": "Basic "+authToke
    },
};

const PushData = async (props) => {
    const postOpts = {
        method: "put",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "Access-Control-Allow-Origin": "*",
            "content-type": "application/json",
            "Authorization": "Basic "+authToke
        },
    };
    postOpts.body = JSON.stringify({message: props});
    let url = "https://backend.wrigglyt.xyz/";
    let output = await fetch(url,postOpts).then(async res => {return res.json();}).then(
        async resBody => {
            let testread = JSON.stringify(resBody.body.message);
            //console.log(typeof testread); //verbosity
            //console.log("Received Confirmation: "+testread); //verbosity
            let tmp = data;
            tmp += testread;
            setData(tmp);
        }
    ).catch(err => {console.log("Pushing error: ");console.log(err);});
};

async function infoBuilder(array){
    let bail = 0;
    array.forEach(e => {
        try{
            let url = jobInfoRequest+e.id;
            fetch(url,reqOpts).then(res => res.json()).then(res => {
                //console.log(res);
                PushData(JSON.stringify(res));
                jsonData.push(res);
            bail += 1;
            })
        }
        catch(err){console.log(err)}
        //console.log(e.id);
    });
    //console.log("JsonDat");
    //console.log(jsonData);
    setJsonD(jsonData);
};

const PullData = async () => {
    let url = htRequestBuild;
    await WipeData();
    try{
        setLoading(true);
        await fetch(url,reqOpts).then(res => res.json()).then(
            resBody => {
                setData("");
                infoBuilder(resBody.data);
                //console.log(resBody);
                //PushData(resBody);
            }
        );
        setLoading(false);
        toast({
            message: 'Data Fetching Complete!',
            duration: 2000,
            type: 'is-success',
            dismissible: false,
            pauseOnHover: true,
            animate: { in: 'fadeIn', out: 'fadeOut' },
        })
    }
    catch(err){
        console.log(err);
        toast({
            message: 'Failed to Fetch data',
            duration: 2000,
            type: 'is-danger',
            dismissible: false,
            pauseOnHover: true,
            animate: { in: 'fadeIn', out: 'fadeOut' },
        })
        //alert("Failed to fetch data for given timeframe");
    }
};

const WipeData = async () => {
    let url = "https://backend.wrigglyt.xyz/WipeCSV";
    const timeout_t = 5000;
    const req2Opts = {
        signal: AbortSignal.timeout(timeout_t),
        method: "PUT",
        mode: "cors",
        cache: "no-cache",
        headers: {
            "content-type": "application/html",
            "Access-Control-Allow-Origin": "*",
        },
    };


    setLoading(true);//We are attempting a fetch
    try{
        await fetch(url,req2Opts).then(res => res.json()).then(
            resBody => {
                setData("");
                //console.log(resBody);
                console.log("CSV Successfully cleared");
                toast({
                    message: 'CSV has been cleared',
                    type: 'is-success',
                    dismissible: true,
                    pauseOnHover: true,
                })
            }
        );
    }
    catch(err){
        console.log(err);
        toast({
            message: 'Request Error: Possibly still wiped csv.',
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
          })
    }finally{
        setLoading(false); //not loading anymore
    }
};

const SendToHomebase = async () => {
    let url = "https://backend.wrigglyt.xyz/SendToHomebase";
    try{
        await fetch(url,reqOpts).then(res => res.json()).then(
            resBody => {
                setData("");
                //console.log(resBody);
                infoBuilder(resBody.data);
                //PushData(resBody);
            }
        );
    }
    catch(err){
        console.log(err);
        alert("Failed to fetch data for given timeframe");
    }
};

function PresentData(){
    try{
        let presentedData = JSON.parse(data);
  //      console.log(presentedData);
        return jsonData;
    }catch(err){
        console.log(err);
    }
};
function getAddress(addressJSON){
    returnJSON = Object.assign({},addressJSON);
	let ret = [];
    for (const e in returnJSON){
        if(addressJSON[e] == null){
        delete returnJSON[e];
        }
    }
	ret.push(returnJSON);
    return ret;
}
function getAddressConverted(addressJSON){
    let returnJSON = ["invalid"];
    try{
        let collec = [];
        for (const [key, value] of Object.entries(addressJSON)) {
            if(value != null && value != undefined){
                if (key == "certificateOfInsurance"){
                    continue;
                }
                else if (key == "country"){
                    continue;
                }
                else if (key == "level"){
                    collec.push(`Access: ${value}`);
                }
                else if (key == "floor"){
                    collec.push(`Floor: ${value}`);
                }
                else if (key == "line1" || key=="line2"){
                    collec.push(`${value}`);
                }
                else if (key=="note" || key=="apartment"){
                    collec.push(`${key}: ${value}`);
                }
                else{
                    collec.push(`${value}`);
                }
            }
          }
        returnJSON = collec;
    }catch(err){
       // console.log(err)
    }
    return returnJSON;
}

function getDuration(time){
    let ntime = time/60;
    return ntime;
}

function getstartTime(hour){
    let checker = hour.split(':');
    let checkerminute = checker[1];
    let tmp = parseInt(hour);
    let newTimehandle = (tmp%12)-1;
    if(tmp>=12 && ((tmp%12)-1)==0){
        newTimehandle += 12;
    }
    let retHandle = (tmp>=12) ? (newTimehandle.toString()+":"+checkerminute+"PM") : (newTimehandle.toString()+":"+checkerminute+"AM");
    return retHandle;
}

function getTime(hour){
    let checker = hour.split(':');
    let checkerminute = checker[1];
    let tmp = parseInt(hour);
    let newTimehandle = tmp%12;
    let retHandle = (tmp>=12) ? (newTimehandle.toString()+":"+checkerminute+"PM") : (newTimehandle.toString()+":"+checkerminute+"AM");
    return retHandle;
}

function GetLoading(loadstate){
    if (loadstate == true){
        return(
            <progress className="progress is-large is-info" max="100">60%</progress>
        );
    }
    else{
        return(
            <></>
        );
    }
}

function GetT(jsonIn){
	let baseoremptymsg = "No Schedule Detected yet";
    if (jsonIn.length < 2){
        return(<div className="box maint">{"No Data Found for Range"}</div>);
    }
    let count = 1;
    // Generate templated html populated with the data from moverbase
    let htmloutput = ( jsonIn.map(function(jsonn,count){
    count += 1;
    
    return(
        <table className="table">
			<tbody className="tbody">
				<tr>
					<td>
						Date: {jsonn.date.toString()}
					</td>
				</tr>
				<tr>
					<td>
						Customer: {jsonn.contact.toString()}
					</td>
				</tr>
				<tr>
					<td>
						Phone: {jsonn.phone.toString()}
					</td>
				</tr>
				<tr>
					<td>
						Shift Start Time: {getstartTime(jsonn.startTime)}
					</td>
				</tr>
				<tr>
					<td>
						Job Start Time: {getTime(jsonn.startTime)}
					</td>
				</tr>
				<tr>
					<td>
						Pickup Address: 
									<br/>
						{
							getAddressConverted(jsonn.addresses.from[0]).map((item)=>{
								return(
									<td>
										{item.toString()}
									</td>
								);
							})
						}
					</td>
				</tr>
				<tr>
					<td>
						Dropoff Address: 
									<br/>
						{
							getAddressConverted(jsonn.addresses.to[0]).map((item)=>{
								return(
									<td>
										{(item)}
										<br/>
									</td>
								);
							})
						}
					</td>
				</tr>
				<tr>
					<td>
						Duration: {(jsonn.duration)/60} hours
					</td>
				</tr>
				<tr>
					<td>
						Vehicle Needed: {JSON.stringify(jsonn.dispatch.vehicle!=null?jsonn.dispatch.vehicle:"No Vehicle has been registered")} 
					</td>
				</tr>
				<tr>
					<td>
					Total Move: {(jsonn.items.total)} Cubic Feet
					</td>
				</tr>
				<tr>
					<td>
						Important Notes: 
					<br/>
						{(jsonn.dispatch.notes)} 
					</td>
				</tr>
				<tr>
					Items being Moved: {(jsonn.items.lines.map((items)=>{return(
						<tr>
							<td>
								Item: {items.name} - Qty: {items.qty}
							</td>
						</tr>
					)}))} 
				</tr>
			</tbody>
		</table>
        );
    }));
    return(
        <div className="jobTableContainer">
			<a href="https://backend.wrigglyt.xyz/PullCSV"><button className="button is-warning">Download CSV for excel</button></a>
				{/*<button className="importbutton" onClick={SendToHomebase}>Send to Homebase</button>*/}
            {htmloutput}
        </div>
        );
}

  return (
    <div className="jobTable">
			{/*URL that is built: {htRequestBuild}*/}
            <br/>
            <button className="button importb is-success" onClick={PullData}>Import Schedule Data</button>
			<br/>
            {/*<a href="http://backend.wrigglyt.xyz/WipeCSV"><button className="importbutton">WipeCSV</button></a>*/}
            <button onClick={WipeData} className="button is-danger">Manually Wipe CSV</button>

            {/*<div className="content">Jobs table will fill once Import Schedule Data is pressed: </div>*/}
            <br/>
            {GetLoading(loading)}
            {GetT(jsonD)}
            <br/>
    </div>
  );
}
